import { $, $$, wrap } from './helper/utils';
import outline from './helper/outline';

outline();

// const sliderMap = new Map();
// const sliderDragging = new Map();
const isMobile = document.documentElement.classList.contains('is-mobile');

export default function () {
  if ($('.custom-select') !== null && isMobile === false) {
    import('./components/select')
      .then((name) => name.default())
      .catch((err) => {
        console.warn('Chunk select.js loading failed', err);
      });
  }

  // if ($('.siema') !== null) {
  //   import('./components/slider')
  //     .then((slider) => slider.default(sliderMap, sliderDragging))
  //     .catch((err) => {
  //       console.warn('Chunk slider.js loading failed', err);
  //     });
  // }

  // if ($('.lightbox') !== null) {
  //   import('./components/lightbox')
  //     .then((lightbox) => lightbox.default('.lightbox', sliderMap, sliderDragging))
  //     .catch((err) => {
  //       console.warn('Chunk lightbox.js loading failed', err);
  //     });
  // }

  if ($('.quicklinks') !== null) {
    import('./components/quicklinks')
      .then((quicklinks) => quicklinks.default())
      .catch((err) => {
        console.warn('Chunk /quicklinks.js loading failed', err);
      });
  }

  if ($('.filter') !== null) {
    import('./components/elementFilter')
      .catch((err) => {
        console.warn('Chunk /elementFilter.js loading failed', err);
      });
  }

  if ($('.nav-tabs') !== null || $('[data-toggle="collapse"]') !== null) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      gsap.fromTo(href, { opacity: 0 }, { duration: 0.3, opacity: 1, ease: 'linear.none' });
    };

    const collapseMap = new Map();
    const showsMore = new Set();
    const toggleText = function () {
      if (showsMore.has(this)) {
        this.textContent = collapseMap.get(this).more;
        showsMore.delete(this);
      } else {
        this.textContent = collapseMap.get(this).less;
        showsMore.add(this);
      }
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }
        const tabs = $$('.nav-tabs .tab-link');
        for (let i = 0; i < tabs.length; i++) {
          const el = tabs[i];

          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }

        const collapseBtns = $$('.btn-collapse');
        for (let i = 0; i < collapseBtns.length; i++) {
          const el = collapseBtns[i];
          const target = $(el.getAttribute('href'));

          if (target !== null) {
            bsn.default.Collapse(el);
            el.addEventListener('click', el.Collapse.toggle);
          }
        }

        // toggle show more button text
        const showHide = $$('[data-showless]');
        for (let i = 0; i < showHide.length; i++) {
          const el = showHide[i];

          collapseMap.set(el, {
            more: el.textContent,
            less: el.dataset.showless,
          });
          el.addEventListener('click', toggleText);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }

  if ($('main table') !== null) {
    const tables = $$('main table');

    for (let i = 0; i < tables.length; i++) {
      const el = tables[i];

      // skip elements that were already wrapped
      if (el.parentElement.classList.contains('table')) {
        continue;
      }

      const wrapper = document.createElement('div');
      wrapper.classList.add('table');
      wrap(el, wrapper);
    }
  }
}
